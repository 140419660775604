/* theme-config.css */

/* 
* todo app router: 
*
* This currently uses the important tag as the radix theme provider is loading  in its style sheet after this one... See if posible fix in postcss 
*/

.radix-themes {
  --default-font-family:
    Lexend, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
